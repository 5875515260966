import React from 'react';

import Bandcamp from './Bandcamp';
import axios from 'axios';

import './SamCreates.css'

export class SamCreatesMusicArt extends React.Component {
    state = {
        musicArtAlbums: {},
        musicArtSingles: {},
        isLoaded: false
    }

    componentDidMount() {
        const getMusicArtAlbums = axios.get('https://samstonedance.com/wp-json/wp/v2/posts?tags=4');
        const getMusicArtSingles = axios.get('https://samstonedance.com/wp-json/wp/v2/posts?tags=12');

        Promise.all([getMusicArtAlbums, getMusicArtSingles]).then(res => {
            this.setState({
                musicArtAlbums: res[0].data,
                musicArtSingles: res[1].data,
                isLoaded: true
            })
        });
    }

    render () {
        const {musicArtAlbums, musicArtSingles, isLoaded} = this.state;
        if(isLoaded) {
            return (
                <>
                <div className = 'sam-music'>
                    <h2 id='music-headline'>Albums</h2>
                    <h3 id='music-headline'>Click on album title for full credits</h3>
                    <div className = 'sam-music-container'>
                    {musicArtAlbums.map((bandcamp, id) => (
                        <Bandcamp key = {bandcamp.id} bandcamp={bandcamp}/>
                    ))}</div>
                </div>
                <div className = 'sam-music'>
                    <h2 id='music-headline'>Singles</h2>
                    <h3 id='music-headline'>Click on song title for full credits</h3>
                    <div className = 'sam-music-container'>
                    {musicArtSingles.map((bandcamp, id) => (
                        <Bandcamp key = {bandcamp.id} bandcamp={bandcamp}/>
                    ))}</div>
                </div>
                </>
            )
        }
        return <div id='loading'><h3>Loading...</h3></div>
    }
}

export default SamCreatesMusicArt;
