import React from 'react';
import Project from './Project';
import axios from 'axios';

import './SamCreates.css'

export class SamCreatesCommunity extends React.Component {
    state = {
        samProjectsCommunity: {},
        isLoaded: false
    }

    componentDidMount() {
        const getCommunity = axios.get('https://samstonedance.com/wp-json/wp/v2/posts?tags=10');

        Promise.all([getCommunity]).then(res => {
            this.setState({
                samProjectsCommunity: res[0].data,
                isLoaded: true
            })
        });
    }

    render () {
        const {samProjectsCommunity, isLoaded} = this.state;
        if(isLoaded) {
            return (
                <div className = 'sam-creates-container'>
                    <div id='community-project-list'>
                    {samProjectsCommunity.map((project, id) => (
                        <Project key = {project.id} project={project}/>
                    ))}
                </div>

                </div>
            )
        }
        return <div id='loading'><h3>Loading...</h3></div>
    }
}

export default SamCreatesCommunity;
