import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel'

export class Bandcamp extends React.Component {
    state = {
        info: '',
        isLoaded: false
    }


    componentDidMount() {
        const { id } = this.props.bandcamp;
        const getInfo = axios.get(`https://samstonedance.com/wp-json/wp/v2/media?parent=${id}`);

        Promise.all([getInfo]).then(res => {
            this.setState({
                info: res[0].data,
                isLoaded: true
            })
        });

    }

    render () {
        const { bandcamp_src, bandcamp_url, bandcamp_name  } = this.props.bandcamp.acf;


            return (
                <div>
                    <div>
                        <iframe className = 'bandcamp' src= {bandcamp_src} seamless><a href={bandcamp_url}>{bandcamp_name}</a></iframe>
                    </div>
                    <hr/>
                </div>
            )

        return null;
    }
}

export default Bandcamp;
