import React from 'react';
import Project from './Project';
import axios from 'axios';

import './SamCreates.css'

export class SamCreatesDances extends React.Component {
    state = {
        samProjectsDances: {},
        isLoaded: false
    }

    componentDidMount() {
        const getDances = axios.get('https://samstonedance.com/wp-json/wp/v2/posts?per_page=100&tags=3');

        Promise.all([getDances]).then(res => {
            this.setState({
                samProjectsDances: res[0].data,
                isLoaded: true
            })
        });
    }

    render () {
        const {samProjectsDances, isLoaded} = this.state;
        if(isLoaded) {
            return (
                <div className = 'sam-creates-container'>
                    <div id='dances-project-list'>
                        {samProjectsDances.map((project, id) => (
                            <Project key = {project.id} project={project}/>
                        ))}
                    </div>
                    <br/>
                </div>
            )
        }
        return <div id='loading'><h3>Loading...</h3></div>
    }
}

export default SamCreatesDances;
