import React from 'react'
import Reel from './Reel';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import './DanceReel.css';

class danceReel extends React.Component {
    state = {
        teachPhilosophy: '',
        isLoaded: false
    }

    componentDidMount() {
        const getReels = axios.get('https://samstonedance.com/wp-json/wp/v2/posts?categories=9')



        Promise.all([getReels]).then(res => {
            this.setState({
                reels: res[0].data,
                isLoaded: true
            })
        }).catch(err => console.log(err));
    }

    render () {
        const { reels, isLoaded } = this.state;


        if(isLoaded) {
            return(

                <div className = 'dance-reel'>
                {reels.map((reel, id) => (
                        <iframe title='dance_reel' width="640" height="385" id='youtube' src={reel.acf.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>


                ))}
            </div>


        )
    }
    return <div id='loading'><h3>Loading...</h3></div>

}
}

export default danceReel;
